<template>
  <div class="m-3">
    <div class="card-modal pt-0">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="false"
      ></loading>
      <h3 class="title">DETALLE DE PUNTOS</h3>
      <div class="row" v-if="point">
        <div class="col-sm-12 col-md-12">
          <div class="card mx-8 p-1">
            <RowComponent
              :title="'PROVINCIA :'"
              :content="point.location.province.province"
            />
            <RowComponent
              :title="'LOCALIDAD :'"
              :content="point.location.location"
            />
            <RowComponent :title="'ALTURA :'" :content="point.height" />
            <RowComponent :title="'CALLE :'" :content="point.postal_code" />
            <RowComponent
              :title="'CODIGO POSTAL :'"
              :content="point.postal_code"
            />
            <div v-if="point.longitude && point.longitude">
              <p>
                <b>MAPA :</b>
                <a :href="url" target="_blank" class="pl-2">
                  <i class="fa fa-map" aria-hidden="true"></i>
                </a>
              </p>
            </div>

            <RowComponent
              :title="'PICKUP :'"
              :content="point.pickup ? 'SI' : 'No'"
            />
            <RowComponent
              :title="'DROPOFF :'"
              :content="point.dropoff ? 'SI' : 'No'"
            />

            <RowComponent :title="'NOTA :'" :content="point.note" />

            <div>
              <p>
                <b>CREADO :</b>
                <span>{{ point.created_at | date_format }}</span>
              </p>
            </div>

            <div>
              <p>
                <b>ACTUALIZADO :</b>
                <span>{{ point.updated_at | date_format }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-2">
        <div class="card-body p-3">
          <h2 class="title">HORARIOS</h2>
          <br />
          <b-list-group>
            <b-list-group-item v-for="item of point.schedules" :key="item.id">
              <span class="font-weight-bold" v-if="item.day === 1">Lunes</span>
              <span class="font-weight-bold" v-if="item.day === 2">Martes</span>
              <span class="font-weight-bold" v-if="item.day === 3"
                >Miercoles</span
              >
              <span class="font-weight-bold" v-if="item.day === 4">Jueves</span>
              <span class="font-weight-bold" v-if="item.day === 5"
                >Viernes</span
              >
              <span class="font-weight-bold" v-if="item.day === 6">Sabado</span>
              <span class="font-weight-bold" v-if="item.day === 7"
                >Domingo</span
              >
              <p>
                {{ item.start | hour_format }} / {{ item.end | hour_format }}
              </p>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>

      <div class="card p-2">
        <div class="card-body p-3">
          <h2 class="title">OTROS DATOS</h2>

          <div v-if="point.attribute">
            <RowComponent
              :title="'REF AGRUPADOR	 :'"
              :content="point.attribute.ref_agrupador"
            />
            <RowComponent :title="'IDCI :'" :content="point.attribute.idci" />

            <RowComponent
              :title="'RAZON SOCIAL AGRUPADOR :'"
              :content="point.attribute.razon_social_agrupador"
            />
            <RowComponent
              :title="'DIRECCION AGRUPADOR :'"
              :content="point.attribute.direccion_agrupador"
            />
            <RowComponent
              :title="'CALLE AGRUPADOR :'"
              :content="point.attribute.calle_agrupador"
            />
            <RowComponent
              :title="'ALT AGRUPADOR :'"
              :content="point.attribute.alt_agrupador"
            />
            <RowComponent
              :title="'E CALLE AGRUPADOR :'"
              :content="point.attribute.e_calle_agrupador"
            />
            <RowComponent
              :title="'BARRIO AGRUPADOR :'"
              :content="point.attribute.barrio_agrupador"
            />
            <RowComponent
              :title="'LOCALIDAD AGRUPADOR :'"
              :content="point.attribute.localidad_agrupador"
            />
            <RowComponent
              :title="'PARTIDO AGRUPADOR :'"
              :content="point.attribute.partido_agrupador"
            />
            <RowComponent
              :title="'PROVINCIA AGRUPADOR :'"
              :content="point.attribute.provincia_agrupador"
            />
            <RowComponent
              :title="'PAIS AGRUPADOR :'"
              :content="point.attribute.pais_agrupador"
            />

            <RowComponent
              :title="'COD POSTAL AGRUPADOR :'"
              :content="point.attribute.cod_post_agrupador"
            />

            <RowComponent
              :title="'MAIL AGRUPADOR :'"
              :content="point.attribute.mail_agrupador"
            />

            <RowComponent
              :title="'CONTACTO AGRUPADOR :'"
              :content="point.attribute.contacto_agrupador"
            />

            <RowComponent
              :title="'REF PUNTO	 :'"
              :content="point.attribute.ref_punto"
            />

            <RowComponent
              :title="'INI HORA1 :'"
              :content="point.attribute.ini_hora1"
            />
            <RowComponent
              :title="'INI HORA2 :'"
              :content="point.attribute.ini_hora2"
            />
            <RowComponent
              :title="'FIN HORA1 :'"
              :content="point.attribute.fin_hora1"
            />
            <RowComponent
              :title="'FIN HORA2 :'"
              :content="point.attribute.fin_hora2"
            />
            <RowComponent
              :title="'CONTACTO :'"
              :content="point.attribute.contacto"
            />

            <RowComponent
              :title="'DIRECCION PUNTO :'"
              :content="point.attribute.direccion_punto"
            />
            <RowComponent
              :title="'CALLE PUNTO	 :'"
              :content="point.attribute.calle_punto"
            />
            <RowComponent
              :title="'ALT PUNTO :'"
              :content="point.attribute.alt_punto"
            />
            <RowComponent
              :title="'E CALLE PUNTO	 :'"
              :content="point.attribute.e_calle_punto"
            />
            <RowComponent
              :title="'BARRIO PUNTO	 :'"
              :content="point.attribute.barrio_punto"
            />
            <RowComponent
              :title="'LOCALIDAD PUNTO	 :'"
              :content="point.attribute.localidad_punto"
            />
            <RowComponent
              :title="'PARTIDO PUNTO	 :'"
              :content="point.attribute.partido_punto"
            />
            <RowComponent
              :title="'PROVINCIA PUNTO	 :'"
              :content="point.attribute.provincia_punto"
            />
            <RowComponent
              :title="'PAIS PUNTO :'"
              :content="point.attribute.pais_punto"
            />
            <RowComponent
              :title="'COD POST PUNTO	 :'"
              :content="point.attribute.cod_post_punto"
            />
            <RowComponent
              :title="'TELE PUNTO :'"
              :content="point.attribute.tele_punto"
            />
            <RowComponent
              :title="'TELE PUNTO2	 :'"
              :content="point.attribute.telepunto2"
            />
            <RowComponent
              :title="'TELE PUNTO3	:'"
              :content="point.attribute.telepunto3"
            />
            <RowComponent
              :title="'MAIL PUNTO :'"
              :content="point.attribute.mail_punto"
            />

            <RowComponent
              :title="'NOMBRE PUNTO :'"
              :content="point.attribute.nombre_punto"
            />
          </div>
        </div>
      </div>
    </div>
    <footer class="mt-2">
      <div class="row">
        <div class="col-12">
          <b-button
            size="sm"
            class="mr-1 float-right"
            variant="primary"
            @click="onClose"
            >Cerrar</b-button
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { BButton, BListGroup, BListGroupItem } from "bootstrap-vue";

import PointService from "@/services/PointService";

export default {
  name: "PointView",
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
  },
  props: {
    id: Number,
  },
  data() {
    return {
      point: null,
      isLoading: false,
    };
  },
  computed: {
    url: function () {
      return (
        "https://www.google.com/maps?q=" +
        this.point.latitude +
        "," +
        this.point.longitude
      );
    },
  },
  mounted() {
    this.show();
  },
  methods: {
    /**
     *
     */
    onClose() {
      this.$emit("close");
    },

    /**
     *
     */
    show() {
      this.isLoading = true;
      PointService.show(this.id)
        .then((response) => {
          const { data } = response;
          this.point = data.data;
          this.isLoading = false;
        })
        .catch((thrown) => {
          this.isLoading = false;
          console.error(thrown);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.card-modal {
  height: 420px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
