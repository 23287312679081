
<template>
  <div class="m-3">
    <div class="card-body card-modal pt-0">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="false"
      ></loading>
      <h3 class="title">Editar Punto</h3>
      <div class="row justify-content-center">
        <div class="col-12">
          <validation-observer ref="editPointForm">
            <b-form @submit="onSubmit">
              <b-tabs>
                <b-tab title="Datos" active>
                  <b-form-group label="Tipo de punto">
                    <b-form-checkbox v-model="point.pickup">
                      PICKUP
                    </b-form-checkbox>
                    <br />
                    <b-form-checkbox v-model="point.dropoff">
                      DROPOFF
                    </b-form-checkbox>
                  </b-form-group>

                  <!-- Provincia -->
                  <b-form-group label="Provincia">
                    <validation-provider
                      #default="{ errors }"
                      name="Provincia"
                      rules="required"
                    >
                      <b-form-select
                        size="sm"
                        name="province"
                        v-model="point.province_id"
                        :options="provinces"
                        @input="fetchLocations"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Localidad -->
                  <b-form-group label="Localidad">
                    <validation-provider
                      #default="{ errors }"
                      name="Localidad"
                      rules="required"
                    >
                      <b-form-select
                        size="sm"
                        name="location"
                        v-model="point.location_id"
                        :options="locations"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Calle -->
                  <b-form-group label="Calle">
                    <validation-provider
                      #default="{ errors }"
                      name="Calle"
                      rules="required|max:250"
                    >
                      <b-form-input
                        size="sm"
                        name="street"
                        max="250"
                        v-model="point.street"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Altura -->
                  <b-form-group label="Altura">
                    <validation-provider
                      #default="{ errors }"
                      name="Altura"
                      rules="required|max:15"
                    >
                      <b-form-input
                        size="sm"
                        name="height"
                        max="15"
                        v-model="point.height"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Codigo Postal -->
                  <b-form-group label="Codigo Postal">
                    <validation-provider
                      #default="{ errors }"
                      name="Codigo Postal"
                      rules="required|max:30"
                    >
                      <b-form-input
                        size="sm"
                        name="postal_code"
                        max="30"
                        v-model="point.postal_code"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Nota -->
                  <b-form-group label="Nota">
                    <validation-provider
                      #default="{ errors }"
                      name="Nota"
                      rules="max:400"
                    >
                      <b-form-textarea
                        size="sm"
                        name="note"
                        xlength="500"
                        v-model="point.note"
                        :state="errors.length > 0 ? false : null"
                      ></b-form-textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Latitud -->
                  <b-form-group label="Latitud">
                    <validation-provider
                      #default="{ errors }"
                      name="Latitud"
                      :rules="{
                        required: true,
                        regex: /^(-?\d{1,3}).([0-9]*)$/,
                        max: 15,
                      }"
                    >
                      <b-form-input
                        size="sm"
                        name="latitude"
                        type="text"
                        max="15"
                        v-model="point.latitude"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                    <span class="text-muted" style="font-size: 11px">
                      Utiliza
                      <a href="https://www.gps-coordinates.net/" target="_blank"
                        >gps-coordinates</a
                      >
                      para obtener la latitud
                    </span>
                  </b-form-group>

                  <!-- Longitud -->
                  <b-form-group label="Longitud">
                    <validation-provider
                      #default="{ errors }"
                      name="Longitud"
                      :rules="{
                        required: true,
                        regex: /^(-?\d{1,3}).([0-9]*)$/,
                        max: 15,
                      }"
                    >
                      <b-form-input
                        size="sm"
                        name="longitude"
                        type="text"
                        max="15"
                        v-model="point.longitude"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                    <span class="text-muted" style="font-size: 11px">
                      Utiliza
                      <a href="https://www.gps-coordinates.net/" target="_blank"
                        >gps-coordinates</a
                      >
                      para obtener la longitud
                    </span>
                  </b-form-group>
                </b-tab>
                <b-tab title="horarios">
                  <b-form-group>
                    <b-form-checkbox v-model="point.is_group_weekdays">
                      Agrupar dias de semana
                    </b-form-checkbox>
                  </b-form-group>

                  <b-list-group>
                    <b-list-group-item v-if="point.is_group_weekdays">
                      <span class="font-weight-bold"> Lunes - Viernes </span>
                      <br />
                      <br />
                      <b-form-group label="Inicio">
                        <b-form-timepicker
                          v-model="point.weekdays.start"
                          :hour12="false"
                        ></b-form-timepicker>
                      </b-form-group>

                      <b-form-group label="Fin">
                        <b-form-timepicker
                          v-model="point.weekdays.end"
                          :hour12="false"
                        ></b-form-timepicker>
                      </b-form-group>
                    </b-list-group-item>

                    <b-list-group-item v-for="item of schedules" :key="item.id">
                      <span class="font-weight-bold">
                        {{ item.day | week_days }}
                      </span>
                      <br />
                      <br />
                      <b-form-group label="Inicio">
                        <b-form-timepicker
                          v-model="item.start"
                          :hour12="false"
                        ></b-form-timepicker>
                      </b-form-group>

                      <b-form-group label="Fin">
                        <b-form-timepicker
                          v-model="item.end"
                          :hour12="false"
                        ></b-form-timepicker>
                      </b-form-group>
                    </b-list-group-item>
                  </b-list-group>
                </b-tab>

                <b-tab title="Otros datos">
                  <!-- REF AGRUPADOR -->
                  <b-form-group label="REF AGRUPADOR">
                    <validation-provider
                      #default="{ errors }"
                      name="REF AGRUPADOR"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="ref_agrupador"
                        max="120"
                        v-model="point.attribute.ref_agrupador"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- REF AGRUPADOR -->
                  <b-form-group label="IDCI">
                    <validation-provider
                      #default="{ errors }"
                      name="IDCI"
                      rules="max:25"
                    >
                      <b-form-input
                        size="sm"
                        name="idci"
                        max="120"
                        v-model="point.attribute.idci"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- CONTACTO AGRUPADOR -->
                  <b-form-group label="CONTACTO AGRUPADOR">
                    <validation-provider
                      #default="{ errors }"
                      name="CONTACTO AGRUPADOR"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="contacto_agrupador"
                        max="120"
                        v-model="point.attribute.contacto_agrupador"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- RAZON SOCIAL AGRUPADOR -->
                  <b-form-group label="RAZON SOCIAL AGRUPADOR">
                    <validation-provider
                      #default="{ errors }"
                      name="RAZON SOCIAL AGRUPADOR"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="razon_social_agrupador"
                        max="120"
                        v-model="point.attribute.razon_social_agrupador"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- DIRECCION AGRUPADOR -->
                  <b-form-group label="DIRECCION AGRUPADOR">
                    <validation-provider
                      #default="{ errors }"
                      name="DIRECCION AGRUPADOR"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="direccion_agrupador"
                        max="120"
                        v-model="point.attribute.direccion_agrupador"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- CALLE AGRUPADOR -->
                  <b-form-group label="CALLE AGRUPADOR">
                    <validation-provider
                      #default="{ errors }"
                      name="CALLE AGRUPADOR"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="calle_agrupador"
                        max="120"
                        v-model="point.attribute.calle_agrupador"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- ALT AGRUPADOR -->
                  <b-form-group label="ALT AGRUPADOR">
                    <validation-provider
                      #default="{ errors }"
                      name="ALT AGRUPADOR"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="alt_agrupador"
                        max="120"
                        v-model="point.attribute.alt_agrupador"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- E CALLE AGRUPADOR -->
                  <b-form-group label="E CALLE AGRUPADOR">
                    <validation-provider
                      #default="{ errors }"
                      name="E CALLE AGRUPADOR"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="e_calle_agrupador"
                        max="120"
                        v-model="point.attribute.e_calle_agrupador"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- BARRIO AGRUPADOR -->
                  <b-form-group label="BARRIO AGRUPADOR">
                    <validation-provider
                      #default="{ errors }"
                      name="BARRIO AGRUPADOR"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="barrio_agrupador"
                        max="120"
                        v-model="point.attribute.barrio_agrupador"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- LOCALIDAD AGRUPADOR -->
                  <b-form-group label="LOCALIDAD AGRUPADOR">
                    <validation-provider
                      #default="{ errors }"
                      name="LOCALIDAD AGRUPADOR"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="localidad_agrupador"
                        max="120"
                        v-model="point.attribute.localidad_agrupador"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- PARTIDO AGRUPADOR -->
                  <b-form-group label="PARTIDO AGRUPADOR">
                    <validation-provider
                      #default="{ errors }"
                      name="PARTIDO AGRUPADOR"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="partido_agrupador"
                        max="120"
                        v-model="point.attribute.partido_agrupador"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- PROVINCIA AGRUPADOR -->
                  <b-form-group label="PROVINCIA AGRUPADOR">
                    <validation-provider
                      #default="{ errors }"
                      name="PROVINCIA AGRUPADOR"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="provincia_agrupador"
                        max="120"
                        v-model="point.attribute.provincia_agrupador"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- PAIS AGRUPADOR -->
                  <b-form-group label="PAIS AGRUPADOR">
                    <validation-provider
                      #default="{ errors }"
                      name="PAIS AGRUPADOR"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="pais_agrupador"
                        max="120"
                        v-model="point.attribute.pais_agrupador"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- COD POSTAL AGRUPADOR -->
                  <b-form-group label="COD POSTAL AGRUPADOR">
                    <validation-provider
                      #default="{ errors }"
                      name="COD POSTAL AGRUPADOR"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="cod_post_agrupador"
                        max="120"
                        v-model="point.attribute.cod_post_agrupador"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- MAIL AGRUPADOR -->
                  <b-form-group label="MAIL AGRUPADOR">
                    <validation-provider
                      #default="{ errors }"
                      name="MAIL AGRUPADOR"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="mail_agrupador"
                        max="120"
                        v-model="point.attribute.mail_agrupador"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- REF AGRUPADOR -->
                  <b-form-group label="REF AGRUPADOR">
                    <validation-provider
                      #default="{ errors }"
                      name="REF AGRUPADOR"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="ref_punto"
                        max="120"
                        v-model="point.attribute.ref_punto"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- INI HORA1 -->
                  <b-form-group label="INI HORA1">
                    <validation-provider
                      #default="{ errors }"
                      name="INI HORA1"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="ini_hora1"
                        max="120"
                        v-model="point.attribute.ini_hora1"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- INI HORA2 -->
                  <b-form-group label="INI HORA2">
                    <validation-provider
                      #default="{ errors }"
                      name="INI HORA2"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="ini_hora2"
                        max="120"
                        v-model="point.attribute.ini_hora2"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- FIN HORA1 -->
                  <b-form-group label="FIN HORA1">
                    <validation-provider
                      #default="{ errors }"
                      name="FIN HORA1"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="fin_hora1"
                        max="120"
                        v-model="point.attribute.fin_hora1"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- FIN HORA2 -->
                  <b-form-group label="FIN HORA2">
                    <validation-provider
                      #default="{ errors }"
                      name="FIN HORA2"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="fin_hora2"
                        max="120"
                        v-model="point.attribute.fin_hora2"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- CONTACTO -->
                  <b-form-group label="CONTACTO">
                    <validation-provider
                      #default="{ errors }"
                      name="CONTACTO"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="contacto"
                        max="120"
                        v-model="point.attribute.contacto"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- ALT PUNTO -->
                  <b-form-group label="ALT PUNTO">
                    <validation-provider
                      #default="{ errors }"
                      name="ALT PUNTO"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="alt_punto"
                        max="120"
                        v-model="point.attribute.alt_punto"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- DIRECCION PUNTO -->
                  <b-form-group label="DIRECCION PUNTO">
                    <validation-provider
                      #default="{ errors }"
                      name="DIRECCION PUNTO"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="direccion_punto"
                        max="120"
                        v-model="point.attribute.direccion_punto"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- CALLE PUNTO -->
                  <b-form-group label="CALLE PUNTO">
                    <validation-provider
                      #default="{ errors }"
                      name="CALLE PUNTO"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="calle_punto"
                        max="120"
                        v-model="point.attribute.calle_punto"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- E CALLE PUNTO -->
                  <b-form-group label="E CALLE PUNTO">
                    <validation-provider
                      #default="{ errors }"
                      name="E CALLE PUNTO"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="e_calle_punto"
                        max="120"
                        v-model="point.attribute.e_calle_punto"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- BARRIO PUNTO -->
                  <b-form-group label="BARRIO PUNTO">
                    <validation-provider
                      #default="{ errors }"
                      name="BARRIO PUNTO"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="barrio_punto"
                        max="120"
                        v-model="point.attribute.barrio_punto"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- LOCALIDAD PUNTO -->
                  <b-form-group label="LOCALIDAD PUNTO">
                    <validation-provider
                      #default="{ errors }"
                      name="LOCALIDAD PUNTO"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="localidad_punto"
                        max="120"
                        v-model="point.attribute.localidad_punto"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- PARTIDO PUNTO -->
                  <b-form-group label="PARTIDO PUNTO">
                    <validation-provider
                      #default="{ errors }"
                      name="PARTIDO PUNTO"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="partido_punto"
                        max="120"
                        v-model="point.attribute.partido_punto"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- PROVINCIA PUNTO -->
                  <b-form-group label="PROVINCIA PUNTO">
                    <validation-provider
                      #default="{ errors }"
                      name="PROVINCIA PUNTO"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="provincia_punto"
                        max="120"
                        v-model="point.attribute.provincia_punto"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- PAIS PUNTO -->
                  <b-form-group label="PAIS PUNTO">
                    <validation-provider
                      #default="{ errors }"
                      name="PAIS PUNTO"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="pais_punto"
                        max="120"
                        v-model="point.attribute.pais_punto"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- COD POST PUNTO -->
                  <b-form-group label="COD POST PUNTO">
                    <validation-provider
                      #default="{ errors }"
                      name="COD POST PUNTO"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="cod_post_punto"
                        max="120"
                        v-model="point.attribute.cod_post_punto"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- TELE PUNTO -->
                  <b-form-group label="TELE PUNTO">
                    <validation-provider
                      #default="{ errors }"
                      name="TELE PUNTO"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="tele_punto"
                        max="120"
                        v-model="point.attribute.tele_punto"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- TELE PUNTO2 -->
                  <b-form-group label="TELE PUNTO2">
                    <validation-provider
                      #default="{ errors }"
                      name="TELE PUNTO2"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="telepunto2"
                        max="120"
                        v-model="point.attribute.telepunto2"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- TELE PUNTO3 -->
                  <b-form-group label="TELE PUNTO3">
                    <validation-provider
                      #default="{ errors }"
                      name="TELE PUNTO3"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="telepunto3"
                        max="120"
                        v-model="point.attribute.telepunto3"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- MAIL PUNTO -->
                  <b-form-group label="MAIL PUNTO">
                    <validation-provider
                      #default="{ errors }"
                      name="MAIL PUNTO"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="mail_punto"
                        max="120"
                        v-model="point.attribute.mail_punto"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- NOMBRE PUNTO -->
                  <b-form-group label="NOMBRE PUNTO">
                    <validation-provider
                      #default="{ errors }"
                      name="NOMBRE PUNTO"
                      rules="max:120"
                    >
                      <b-form-input
                        size="sm"
                        name="nombre_punto"
                        max="120"
                        v-model="point.attribute.nombre_punto"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-tab>
              </b-tabs>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
    <footer class="mt-2">
      <div class="row">
        <div class="col-12">
          <b-button
            size="sm"
            class="mr-1 float-right"
            variant="primary"
            @click="onSubmit"
            >Guardar</b-button
          >
          <b-button
            size="sm"
            class="mr-1 float-right"
            variant="outline-primary"
            @click="close"
            >Cancelar</b-button
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BForm,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BFormTimepicker,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required } from "@validations";

import LocationService from "@/services/LocationService";
import PointService from "@/services/PointService";

export default {
  name: "PointEdit",
  components: {
    BForm,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BFormTimepicker,
    BButton,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    onClose: Object,
    onSave: Object,
    id: Number,
  },
  data() {
    return {
      required,
      isLoading: false,
      provinces: [],
      locations: [],
      point: {
        id: null,
        province_id: null,
        location_id: null,
        street: null,
        height: null,
        pickup: true,
        dropoff: true,
        note: null,
        postal_code: null,
        latitude: null,
        longitude: null,
        is_group_weekdays: false,
        weekdays: {
          start: "07:00:00",
          end: "16:00:00",
        },
        attribute: {},
        schedules: [
          {
            id: null,
            day: 1,
            start: "07:00:00",
            end: "16:00:00",
          },
          {
            id: null,
            day: 2,
            start: "08:00:00",
            end: "16:00:00",
          },
          {
            id: null,
            day: 3,
            start: "08:00:00",
            end: "16:00:00",
          },
          {
            id: null,
            day: 4,
            start: "08:00:00",
            end: "16:00:00",
          },
          {
            id: null,
            day: 5,
            start: "08:00:00",
            end: "16:00:00",
          },
          {
            id: null,
            day: 6,
            start: "08:00:00",
            end: "16:00:00",
          },
          {
            id: null,
            day: 7,
            start: "08:00:00",
            end: "16:00:00",
          },
        ],
      },
    };
  },
  methods: {
    /**
     *
     */
    onSubmit(event) {
      event.preventDefault();
      this.$refs.editPointForm.validate().then((valid) => {
        if (valid) {
          this.point.schedules = this.point.schedules.map((item) => {
            if (this.point.is_group_weekdays && item.day > 0 && item.day < 6) {
              return Object.assign({}, item, this.point.weekdays);
            }
            return item;
          });
          PointService.save(this.point, this.point.id)
            .then(({ data }) => {
              this.$emit("close");
              this.onSave.handler(data);
            })
            .catch((error) => {
              console.error(error);
              const response = error.response;
              if (response && response.status === 422) {
                const keys = Object.keys(response.data.errors);
                keys.forEach((key) => {
                  response.data.errors[key].forEach((error) => {
                    this.veeErrors.add({
                      field: key,
                      msg: error,
                    });
                  });
                });
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Actualizar`,
                    text: `Error al tratar de almacenar este elemento`,
                    icon: "AlertOctagonIcon",
                    variant: "danger",
                  },
                });
              }
            });
        }
      });
    },

    /**
     *
     */
    async fetchLocations() {
      const { data } = await LocationService.locations({
        province_id: this.point.province_id,
      });
      this.locations = data.data;
    },

    /**
     *
     */
    async fetchProvinces() {
      const { data } = await LocationService.provinces();
      this.provinces = data.data;
    },

    /**
     *
     */
    close() {
      this.$emit("close");
      this.onClose.handler();
    },

    /**
     *
     */
    async show() {
      const response = await PointService.show(this.id);
      this.point = response.data.data;
      this.point.province_id = this.point.location.province_id;
    },
  },

  computed: {
    schedules: function () {
      if (this.point.is_group_weekdays) {
        return this.point.schedules.filter((item) => {
          return item.day === 6 || item.day === 7;
        });
      }
      return this.point.schedules;
    },
  },
  /**
   *
   */
  async mounted() {
    try {
      this.isLoading = true;
      if (this.id) {
        await this.show();
      }
      await this.fetchProvinces();
      if (this.point) {
        await this.fetchLocations();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    }
  },
};
</script>

<style lang="scss" scoped>
.card-modal {
  height: 560px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
